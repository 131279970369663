import React from 'react';
import { Layout, SEO } from '../layout';
import {
	FadeInWhenVisibleLeft,
	FadeInWhenVisibleRight,
} from '../components/FadeInWhenVisible';

import 'swiper/css';
import 'swiper/css/navigation';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { motion } from 'framer-motion';
import WithTransition from '../components/WithTransition';
import { StaticImage } from 'gatsby-plugin-image';

SwiperCore.use([Navigation]);

const CarouselImages = () => {
	return (
		<>
			<Swiper
				pagination={{
					type: 'fraction',
				}}
				navigation={true}
				className="mySwiper">
				<SwiperSlide>
					{' '}
					<StaticImage
						src="../images/Sargaco/IMG_8291.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img6.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img11.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img3.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img5.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/IMG_8372.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img2.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/IMG_8307.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img8.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/Sargaco/img9.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<StaticImage
						src="../images/IMG_8281.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
			</Swiper>
		</>
	);
};

function PageJobSargaco() {
	const fadeLeft = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};
	return (
		<Layout>
			<SEO title="Sarçago" />
			<section className="">
				<div className=" ">
					<section className="container_page_jobs">
						<motion.h1
							className="title_page"
							variants={fadeLeft}
							initial="hidden"
							animate="visible"
							transition={{ duration: 1 }}>
							Sargaço
						</motion.h1>

						<motion.img
							whileTap={{ scale: 0.9 }}
							drag={true}
							dragConstraints={{
								left: 0,
								right: 250,
								top: 0,
								bottom: 50,
							}}
							initial={{ opacity: 0, y: -100 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { duration: 1 },
							}}
							className="first_image"
							src="../images/Sargaco/img1.JPG"
						/>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									Sargaço é um solo de dança que se propõe a
									alargar as possibilidades de interações
									afetivas entre corpo-imagem-superfície,
									dentro de uma perspectiva erótica. O prazer
									como o princípio gerador do movimento. É na
									interface desses encontros que a experiência
									erótica é possível. Qual o repertório de
									prazer que compõe um corpo? Como atualizar e
									deslocar registros fixos? O solo é uma
									grande busca de fundir-se entre as matérias,
									fluídos e possíveis forças invisíveis
									evocadas. SARGAÇO flutua em superfícies
									úmidas e penetra com desejo de profundidade.
									O trabalho estreou em 2021 em formato de
									audiovisual em decorrência da pandemia. Foi
									um projeto realizado com apoio financeiro da
									lei Aldir Blanc, juntamente com a Secretaria
									do Estado de Alagoas.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<FadeInWhenVisibleLeft>
							<div className="container_text_job">
								<img
									className="second_image"
									src="../images/Sargaco/img14.JPG"
								/>
							</div>
						</FadeInWhenVisibleLeft>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									A pesquisa do solo estende o olhar para a
									qualidade das interações e das potências
									evocadas a partir da descentralização do
									corpo humano como principal condutor das
									relações, propondo a ideia de
									horizontalidade entre os elementos
									‘corpo-imagem-superfície’ como uma
									organização autogeradora de intensidades,
									agenciamentos de afetos e produção de
									subjetivação. As práticas corporais criadas
									para acessar campos de ‘deslocamento de
									registros’, ‘interações não hierarquizadas’,
									‘atualização do desejo’, ‘produção de
									fluídos como tecnologia disforme’ e a
									‘evocação de forças como canal para o
									inconsciente’, construiu um amontoado de
									procedimentos eróticos para serem
									partilhados em formato de oficina e dar
									continuidade no processo de pesquisa do
									espetáculo.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<CarouselImages />
						<div className="infos">
							<p className="text_infos"></p>
							<img className="image_infos"></img>
						</div>
					</section>
				</div>
				<div className="section_infos">
					<section className="container_infos">
						<p className="text_bold">Ficha técnica:</p>
						<div className="texts_infos">
							<p className="text_bold">
								Concepção, direção, coreografia y performance:
							</p>
							<p className="text_indent">Mirê Pi</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Colaboração dramatúrgica:
							</p>
							<p className="text_indent">Kamilla Mesquita</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Consultoria artística:</p>
							<p className="text_indent">Caio Jade</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Concepção dos vídeos projetados:
							</p>
							<p className="text_indent">Luiza Leal</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Trilha sonora:</p>
							<p className="text_indent">Ana Galganni</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Figurino:</p>
							<p className="text_indent">
								Ateliê Thalita Almeida
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Arte gráfica:</p>
							<p className="text_indent">Laís Cavalcanti</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Produção executiva:</p>
							<p className="text_indent">Mirê Pi</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Direção de produção:</p>
							<p className="text_indent">Adda Feitosa</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Still y Assistência de produção:
							</p>
							<p className="text_indent">Amanda Môa</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Operação de luz:</p>
							<p className="text_indent">Jessé Batista</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">
								Técnico de projeção mapeada:
							</p>
							<p className="text_indent">Ulysses Ribas</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Operação de câmeras:</p>
							<p className="text_indent">
								Victor Viana, Raphael Pires y Luiza Leal
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Edição e finalização:</p>
							<p className="text_indent">
								Victor Viana y Raphael Pires
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Livre</p>
						</div>
						<br></br>

						<p className="text_bold">Apoio cultural:</p>
						<p className="text_release ">
							{' '}
							<p>Escola Técnica de Artes</p> 
							<p>Cia dos Pés</p>
							<p>Piracema Studio</p>
							<p>Coletivo Heteaçã</p>
						</p>
					</section>
				</div>
			</section>
		</Layout>
	);
}

export default WithTransition(PageJobSargaco);
